import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import Cookies from 'js-cookie';
const { decrypt } = require('../helpers/encryption-decryption');
const domainpath = process.env.REACT_APP_DOMAIN_ENDPOINT;

const { SubMenu } = Menu;
var UserRole = [];

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );
  const [RoleName, setRoleName] = useState();
  const [userRoleName, setUserRoleName] = useState();
  useEffect(() => {
    var enc_userDetail = Cookies.get('UserDetail');
    var response = decrypt(enc_userDetail);
    console.log(response);
    if (response?.login == true) {
      const UserInfo = response?.sessdata?.user?.[0];
      const GetRole = UserInfo?.user_role?.toUpperCase();
      setUserRoleName(GetRole);
      setRoleName(GetRole);
      if (UserInfo?.permissions != 'No Permission for role') {
        const modules = UserInfo?.permissions?.[GetRole].MODULES;
        UserRole = modules;
        console.log(UserRole);
      }
    }
  }, []);

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };
  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
      gutter={30}
      className="custmblock"
    >
      {UserRole != undefined ? (
        <>
          {UserRole['DASHBOARD'] !== undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink
                    className="menuItem-iocn"
                    // to={`${path}/customer-lislt`}
                    to={''}
                  >
                    <FeatherIcon icon="home" />
                  </NavLink>
                )
              }
              key="h_dashboard"
            >
              {RoleName == 'CLIENT' ? (
                <NavLink onClick={toggleCollapsed} to={`${path}/teacher`}>
                  Dashboard
                </NavLink>
              ) : RoleName == 'STUDENT' ? (
                <NavLink onClick={toggleCollapsed} to={`${path}/studenthome`}>
                  Dashboard
                </NavLink>
              ) : (
                <NavLink onClick={toggleCollapsed} to={`${path}/admin`}>
                  Dashboard
                </NavLink>
              )}
            </Menu.Item>
          ) : (
            ''
          )}

          {UserRole['MESSAGE LOGS'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/pin-listgg`}>
                    <FeatherIcon icon="message-square" />
                  </NavLink>
                )
              }
              key="message_logs"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/message_logs`}>
                Message Logs
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}
          {UserRole['MESSAGE LOGS'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/pin-listgg`}>
                    <FeatherIcon icon="activity" />
                  </NavLink>
                )
              }
              key="trigger_logs"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/trigger_logs`}>
                Trigger Logs
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}

          {UserRole['MESSAGE LOGS'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/pin-listgg`}>
                    <FeatherIcon icon="activity" />
                  </NavLink>
                )
              }
              key="ai_logs"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/trigger_logs`}>
                Ai Logs
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}

          {UserRole['MESSAGE LOGS'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/pin-listgg`}>
                    <FeatherIcon icon="layers" />
                  </NavLink>
                )
              }
              key="leads"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/leads`}>
                Leads
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}
          {UserRole['WEBHOOK'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/pin-listgg`}>
                    <FeatherIcon icon="share-2" />
                  </NavLink>
                )
              }
              key="webhook"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/webhook`}>
                Webhook
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}

          {UserRole['AGENT'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/pin-listgg`}>
                    <FeatherIcon icon="user" />
                  </NavLink>
                )
              }
              key="client"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/client`}>
                Client
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}
          {/* {UserRole['AGENT'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/pin-listgg`}>
                    <FeatherIcon icon="bar-chart-2" />
                  </NavLink>
                )
              }
              key="gohighlevelplatform"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/gohighlevelplatform`}>
                Go HighLevel Platform
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )} */}

          {UserRole['AGENT'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/pin-listgg`}>
                    <img src="https://glances.com/img/apps/png/gohighlevel.png" style={{ width: '20px' }} />
                  </NavLink>
                )
              }
              key="gohighlevel"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/accounts`}>
                Go HighLevel
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}

          {/* {UserRole['GOOGLESHEET'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/pin-listgg`}>
                    <FeatherIcon icon="file" />
                  </NavLink>
                )
              }
              key="googlesheet"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/googlesheet`}>
                Google Sheet
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}
          {UserRole['HOME PAGE SETTINGS'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/pin-listgg`}>
                    <FeatherIcon icon="settings" />
                  </NavLink>
                )
              }
              key="homepage_settings"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/homepage-settings`}>
                Home Page Settings
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )} */}
          {UserRole['HOME PAGE SETTINGS'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/pin-listgg`}>
                    <FeatherIcon icon="settings" />
                  </NavLink>
                )
              }
              key="homepage_settings"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/settings-voice`}>
                Settings
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
