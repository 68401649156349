import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Dashboard = lazy(() => import('../../container/dashboard'));
const Ecommerce = lazy(() => import('../../container/dashboard/Ecommerce'));
const Business = lazy(() => import('../../container/dashboard/Business'));
const Performance = lazy(() => import('../../container/dashboard/Performance'));
const CRM = lazy(() => import('../../container/dashboard/CRM'));
const AddPins = lazy(() => import('../../container/pages/AddPins'));
// const QuizzesList = lazy(() => import('../../container/pages/QuizzesList'));

//
// const AddPost = lazy(() => import('../../container/pages/AddPost'));
// const EditPost = lazy(() => import('../../container/pages/EditPost'));
// const ViewPost = lazy(() => import('../../container/pages/ViewPost'));

// const AddPins = lazy(() => import('../../container/pages/AddPins'));
const StudentDashboard = lazy(() => import('../../container/dashboard/StudentDashboard'));
const TeacherDashboard = lazy(() => import('../../container/pages/ClientDashboard'));
const StudentHome = lazy(() => import('../../container/dashboard/StudentDashboard'));
const UsersList = lazy(() => import('../../container/pages/UsersList'));
const AddUserRole = lazy(() => import('../../container/pages/AddUsersRole'));
const Roles_Permissions = lazy(() => import('../../container/pages/EditUsersRole'));
const UserGroup = lazy(() => import('../../container/pages/UserGroup'));
const Configuration = lazy(() => import('../../container/pages/Configuration'));
const SocialAccounts = lazy(() => import('../../container/pages/SocialAccounts'));

const GeneralSetting = lazy(() => import('../../container/pages/GeneralSetting'));
const LocalSettings = lazy(() => import('../../container/pages/LocalSettings'));
const HomePageSetting = lazy(() => import('../../container/pages/HomePageSetting'));
const EmailSetting = lazy(() => import('../../container/pages/EmailSetting'));
const PaymentSetting = lazy(() => import('../../container/pages/PaymentSetting'));
const BillandTaxSetting = lazy(() => import('../../container/pages/BillandTaxSetting'));
const ThemeSetting = lazy(() => import('../../container/pages/ThemeSetting'));
const MainteneceSetting = lazy(() => import('../../container/pages/MainteneceSetting'));
const Home = lazy(() => import('../../container/pages/RequestQuote'));
const Client = lazy(() => import('../../container/pages/Client'));
const Leads = lazy(() => import('../../container/pages/Leads'));
const Templates = lazy(() => import('../../container/pages/Templates'));
const SlyBroadCast = lazy(() => import('../../container/pages/SlyBroadCast'));
const Contact = lazy(() => import('../../container/pages/Contact'));
const ElevenLabs = lazy(() => import('../../container/pages/ElevenLabs'));
const Models = lazy(() => import('../../container/pages/Models'));
const ContactHistory = lazy(() => import('../../container/pages/ContactHistory'));
const SettingsVoice = lazy(() => import('../../container/pages/SettingVoice'));
const MessageLog = lazy(() => import('../../container/pages/MessageLog'));
// const ApplicationAi = lazy(() => import('../../container/pages/ApplicationAi'));
const EdiApplication = lazy(() => import('../../container/pages/EdiApplication'));
const TriggerLog = lazy(() => import('../../container/pages/TriggerLog'));
const Webhook = lazy(() => import('../../container/pages/Webhook'));
const Gohighlevel = lazy(() => import('../../container/pages/Gohighlevel'));
const Pin = lazy(() => import('../../container/pages/Pin'));
const PinSubaccount = lazy(() => import('../../container/pages/PinSubaccount'));

const AddMessageLogs = lazy(() => import('../../container/pages/AddMessageLogs'));
const EditMessageLogs = lazy(() => import('../../container/pages/EditMessageLogs'));
const AddEvents = lazy(() => import('../../container/pages/AddEvents'));
const EditEvents = lazy(() => import('../../container/pages/EditEvents'));
const LeadsAi = lazy(() => import('../../container/pages/LeadsAi'));
//const EditMessageLogs = lazy(() => import('../../container/pages/EditMessageLogs'));

const DashboardRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      {/* 1 */}
      <Route exact path={path} component={Dashboard} />
      <Route path={`${path}/social`} component={Dashboard} />
      <Route exact path={`${path}/eco`} component={Ecommerce} />
      <Route exact path={`${path}/business`} component={Business} />
      <Route exact path={`${path}/performance`} component={Performance} />
      <Route exact path={`${path}/crm`} component={CRM} />
      <Route exact path={`${path}/admin`} component={AddPins} />
      <Route path={`${path}/configuration/users`} component={UsersList} />
      <Route path={`${path}/configuration/add-role`} component={AddUserRole} />
      <Route path={`${path}/configuration/usergroup`} component={UserGroup} />
      <Route path={`${path}/configuration/roles-permissions`} component={Roles_Permissions} />

      {/* Start  Settings */}
      <Route path={`${path}/configuration/general-settings`} component={GeneralSetting} />
      <Route path={`${path}/configuration/local-settings`} component={LocalSettings} />
      <Route path={`${path}/homepage-settings`} component={HomePageSetting} />
      <Route path={`${path}/configuration/email-settings`} component={EmailSetting} />
      <Route path={`${path}/configuration/payment-settings`} component={PaymentSetting} />
      <Route path={`${path}/configuration/billandtax-settings`} component={BillandTaxSetting} />
      <Route path={`${path}/configuration/theme-settings`} component={ThemeSetting} />
      <Route path={`${path}/configuration/maintenance-settings`} component={MainteneceSetting} />
      <Route exact path="/" component={Home} />
      <Route path={`${path}/student`} component={StudentDashboard} />
      <Route path={`${path}/teacher`} component={TeacherDashboard} />
      <Route path={`${path}/studenthome`} component={StudentHome} />
      <Route path={`${path}/configuration`} component={Configuration} />

      <Route path={`${path}/client`} component={Client} />
      <Route path={`${path}/templates`} component={Templates} />
      <Route path={`${path}/contacts`} component={Contact} />
      <Route path={`${path}/sly_broadcast`} component={SlyBroadCast} />
      <Route path={`${path}/eleven_labs`} component={ElevenLabs} />
      <Route path={`${path}/models`} component={Models} />
      <Route path={`${path}/leadsai`} component={LeadsAi} />
      <Route path={`${path}/gohighlevelplatform`} component={Pin} />
      <Route path={`${path}/gohighlevelplatform_sub`} component={PinSubaccount} />

      <Route path={`${path}/ContactHistory/:id`} component={ContactHistory} />
      <Route path={`${path}/settings-voice`} component={SettingsVoice} />
      <Route path={`${path}/message_logs`} component={MessageLog} />
      {/* <Route path={`${path}/application_ai`} component={ApplicationAi} /> */}
      <Route path={`${path}/editapplication/:id`} component={EdiApplication} />
      <Route path={`${path}/trigger_logs`} component={TriggerLog} />
      <Route path={`${path}/webhook`} component={Webhook} />
      <Route path={`${path}/accounts`} component={Gohighlevel} />

      <Route path={`${path}/addmessage_logs`} component={AddMessageLogs} />
      <Route path={`${path}/addEvents`} component={AddEvents} />
      <Route path={`${path}/EditEvents/:id`} component={EditEvents} />
      <Route path={`${path}/leads`} component={Leads} />
      <Route path={`${path}/editmessage_logs/:id`} component={EditMessageLogs} />

      <Route exact path={`${path}/googlesheet`} component={SocialAccounts} />
    </Switch>
  );
};

export default DashboardRoutes;
